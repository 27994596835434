import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'RightPanel',
  props: {
    clickNotClose: {
      default: false,
      type: Boolean
    },
    buttonTop: {
      default: 250,
      type: Number
    }
  },
  data: function data() {
    return {
      show: false
    };
  },
  computed: {
    theme: function theme() {
      return this.$store.state.setting.theme;
    },
    showSettingBar: function showSettingBar() {
      return this.$store.state.setting.settingBar.opened;
    }
  },
  mounted: function mounted() {
    this.insertToBody();
    this.addEventClick();
  },
  beforeDestroy: function beforeDestroy() {
    var elx = this.$refs.rightPanel;
    elx.remove();
  },
  methods: {
    addEventClick: function addEventClick() {
      window.addEventListener('click', this.closeSidebar);
    },
    closeSidebar: function closeSidebar(evt) {
      var parent = evt.target.closest('.rightPanel');
      if (!parent) {
        this.$store.commit('setting/openSettingBar', false);
        window.removeEventListener('click', this.closeSidebar);
      }
    },
    insertToBody: function insertToBody() {
      var elx = this.$refs.rightPanel;
      var body = document.querySelector('body');
      body.insertBefore(elx, body.firstChild);
    }
  }
};