import "core-js/modules/es7.array.includes";
import _objectSpread from "/Users/admin/IdeaProjects/credit-ms-web/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
import Vue from 'vue';
import Router from 'vue-router';
import Layout from '@/layout';
import db from '@/utils/localstorage';
import request from '@/utils/request';
import store from '@/store/index';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { getUrlMethod } from '@/utils';
Vue.use(Router);
var constRouter = [{
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component(resolve) {
      return require(['@/views/redirect/index'], resolve);
    }
  }]
}, {
  path: '/404',
  component: function component(resolve) {
    return require(['@/views/error-page/404'], resolve);
  },
  hidden: true
}, {
  path: '/login',
  name: '登录页',
  component: function component() {
    return import('@/views/login/index');
  }
}, {
  path: '/',
  component: Layout,
  redirect: '/profile/index',
  children: [{
    path: 'index',
    component: function component(resolve) {
      return require(['@/views/profile/index'], resolve);
    },
    name: 'Profile',
    meta: {
      title: 'profile',
      icon: 'user',
      noCache: true,
      affix: true
    }
  }, {
    path: 'contractDetail',
    component: function component() {
      return import('@/views/common/system/contractDetail/index');
    },
    name: 'contractDetail',
    meta: {
      title: 'contractDetail',
      icon: 'user',
      noCache: true,
      affix: true
    }
  }, {
    path: '/finance/intecon/promissory',
    component: function component() {
      return import('@/views/common/system/promissory/Index');
    },
    name: 'promissory',
    meta: {
      title: 'promissory',
      icon: 'user',
      noCache: true,
      affix: true
    }
  }, {
    path: '/finance/intecon/instalment',
    component: function component() {
      return import('@/views/common/system/instalment/Index');
    },
    name: 'instalment',
    meta: {
      title: 'promissory',
      icon: 'user',
      noCache: true,
      affix: true
    }
  }, {
    path: '/finance/dashboard/index',
    component: function component() {
      return import('@/views/dashboard/index');
    },
    name: 'dashboard',
    meta: {
      title: 'promissory',
      icon: 'user',
      noCache: true,
      affix: true
    }
  }, {
    path: '/checking',
    component: function component() {
      return import('@/views/common/system/checking/index');
    },
    name: 'checking',
    meta: {
      title: 'promissory',
      icon: 'user',
      noCache: true,
      affix: true
    }
  }, {
    path: '/check',
    component: function component() {
      return import('@/views/common/system/check/index');
    },
    name: 'checking',
    meta: {
      title: 'promissory',
      icon: 'user',
      noCache: true,
      affix: true
    }
  }, {
    path: '/finance/accountBook',
    component: function component() {
      return import('@/views/common/system/accountBook/index');
    },
    name: 'accountBook',
    meta: {
      title: 'promissory',
      icon: 'user',
      noCache: true,
      affix: true
    }
  }, {
    path: '/finance/card',
    component: function component() {
      return import('@/views/common/system/card/index');
    },
    name: 'card',
    meta: {
      title: 'promissory',
      icon: 'user',
      noCache: true,
      affix: true
    }
  }, {
    path: '/system/transaction',
    component: function component() {
      return import('@/views/common/system/transactionRecord/index');
    },
    name: 'transaction',
    meta: {
      title: 'promissory',
      icon: 'user',
      noCache: true,
      affix: true
    }
  }]
}, {
  path: '/profile',
  component: Layout,
  redirect: '/profile/index',
  hidden: true,
  children: [{
    path: 'index',
    component: function component(resolve) {
      return require(['@/views/profile/index'], resolve);
    },
    name: 'Profile',
    meta: {
      title: 'profile',
      icon: 'user',
      noCache: true
    }
  }]
}, {
  path: '/error',
  component: Layout,
  redirect: 'noRedirect',
  name: 'ErrorPages',
  meta: {
    title: 'errorPages',
    icon: '404'
  },
  children: [{
    path: '404',
    component: function component(resolve) {
      return require(['@/views/error-page/404'], resolve);
    },
    name: 'Page404',
    meta: {
      title: 'page404',
      noCache: true
    }
  }]
}];
var router = new Router({
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  routes: constRouter
});
var whiteList = ['/login'];
var asyncRouter;

// 导航守卫，渲染动态路由
router.beforeEach(function (to, from, next) {
  NProgress.start();
  if (whiteList.indexOf(to.path) !== -1) {
    next();
  } else {
    var token = db.get('ACCESS_TOKEN');
    var user = db.get('USER');
    console.log(db.get('CLIENTID'), '异步路由');
    if (token && token !== 'undefined' && token !== 'null' && token !== '' && user) {
      if (store.getters.asyncRouterArr.length === 0) {
        // if (!userRouter || userRouter === 'undefined' || userRouter === 'null' || userRouter === '' || Object.keys(userRouter).length === 0) {
        var clientId = 'uaa';
        request.get("system/menu/".concat(user.username, "/").concat(clientId)).then(function (res) {
          var permissions = res.data.data.permissions;
          store.commit('account/setPermissions', permissions);
          asyncRouter = res.data.data.routes;
          store.commit('account/setRoutes', asyncRouter);
          save('USER_ROUTER', asyncRouter);
          go(to, next);
        }).catch(function () {
          next('/login');
        });
        // } else {
        //   asyncRouter = userRouter
        //   go(to, next)
        // }
      } else {
        next();
      }
    } else {
      if (to.path === '/login') {
        next();
      } else {
        next('/login');
      }
    }
  }
});
router.afterEach(function () {
  NProgress.done();
});
function go(to, next) {
  asyncRouter = filterAsyncRouter(asyncRouter);
  router.addRoutes(asyncRouter);
  next(_objectSpread(_objectSpread({}, to), {}, {
    replace: true
  }));
}
function save(name, data) {
  localStorage.setItem(name, JSON.stringify(data));
}
function get(name) {
  return JSON.parse(localStorage.getItem(name));
}
function filterAsyncRouter(routes) {
  return routes.filter(function (route) {
    var component = route.component;
    if (component) {
      if (route.component === 'Layout') {
        route.component = Layout;
      } else {
        route.component = view(component);
      }
      if (route.children && route.children.length) {
        route.children = filterAsyncRouter(route.children);
      }
      return true;
    }
  });
}
function view(path) {
  var arr = ['common/system/contractDetail/index', 'common/system/promissory/Index', 'common/system/instalment/Index', '/dashboard/index', 'common/system/checking/index', 'common/system/accountBook/index', 'common/system/card/index', 'common/system/check/index', 'common/system/transactionRecord/index'];
  if (!arr.includes(path)) {
    return function (resolve) {
      return require(["@/views/".concat(path, ".vue")], resolve);
    };
  } else {
    console.log(path, "path888");
    return null;
  }
}
export default router;