//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ThemePicker from '@/components/ThemePicker';
export default {
  components: {
    ThemePicker: ThemePicker
  },
  data: function data() {
    return {};
  },
  computed: {
    fixedHeader: {
      get: function get() {
        return this.$store.state.setting.fixHeader;
      },
      set: function set(val) {
        this.$store.commit('setting/fixHeader', val);
      }
    },
    tagsView: {
      get: function get() {
        return this.$store.state.setting.multipage;
      },
      set: function set(val) {
        this.$store.commit('setting/setMultipage', val);
      }
    },
    sidebarLogo: {
      get: function get() {
        return this.$store.state.setting.sidebarLogo;
      },
      set: function set(val) {
        this.$store.commit('setting/setSidebarLogo', val);
      }
    }
  },
  methods: {
    themeChange: function themeChange(val) {
      this.$store.commit('setting/setTheme', val);
    }
  }
};